<template>
  <div class="bg-gray-main mb-12">
    <div v-if="dataLoaded" class="container py-20">
      <div class="block lg:flex lg:space-x-4">
        <div
          class="
            lg:w-8/12
            border border-l-0
            lg:border-r
            border-r-0 border-t-0 border-b-0 border-gray-border
          "
        >
          <header>
            <p>{{ jobFamily }} Salaries</p>
            <h1 class="mt-2 text-4xl font-bold">{{ jobName }}</h1>
          </header>

          <div class="mt-12">
            <bar-chart
              v-if="dataLoaded"
              :chart-labels="chartLabels"
              :styles="graphStyle"
              :chart-data="datacollection"
            ></bar-chart>
          </div>
        </div>
        <div class="lg:w-4/12">
          <div
            class="
              pb-4
              border border-gray-border border-r-0 border-l-0 border-t-0
            "
          >
            <small>Median Total Salary</small>
            <h1 class="text-purple text-3xl font-bold">
              ₦ {{ percentiles[1] }}
            </h1>
          </div>
          <div
            class="
              py-4
              border border-gray-border border-r-0 border-l-0 border-t-0
              space-y-4
            "
          >
            <div>
              <small>25th Percentile</small>
              <p>₦ {{ percentiles[0] }}</p>
            </div>
            <div>
              <small>75th Percentile</small>
              <p>₦ {{ percentiles[2] }}</p>
            </div>
            <div>
              <small>90th Percentile</small>
              <p>₦ {{ percentiles[3] }}</p>
            </div>
          </div>
          <div class="py-4">
            <small>Similar Areas</small>
            <div class="mt-2 flex flex-wrap items-center">
              <router-link
                v-for="job in relatedJobs"
                :key="job.value"
                :to="{ name: 'JobsSingle', params: { id: job.value } }"
              >
                <div
                  class="
                    bg-purple bg-opacity-20
                    rounded-full
                    px-4
                    py-2
                    mr-2
                    mb-2
                  "
                >
                  <p class="text-sm">{{ job.name }}</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-24">
        <div class="flex justify-end mb-8 space-x-6">
          <Button
            custom_class="text-sm"
            text="Add Salary"
            :icon="['fas', 'plus']"
            @click="$router.push({ name: 'Add Salary' })"
          />
        </div>
        <SalariesTable
          id="salaries-table"
          ref="salariesTable"
          :per_page="perPage"
          @per-page-changed="perPageChange"
          :columns="salaryColumns"
          :endpoint="salariesApiEndpoint"
        />
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { FILTERS } from "@/helpers/filters.js";
import Button from "@/components/core/Button.vue";
import Loading from "../components/core/Loading.vue";
import BarChart from "../components/core/BarChart.vue";
import SalariesTable from "../components/derived/SalariesTable.vue";
import {
  jobFamilies,
  getJobFamilyNameFromChildId,
  getAllRelatedJobs,
} from "../helpers/enums";

export default {
  name: "JobsSingle",
  components: { BarChart, Button, Loading, SalariesTable },
  computed: {
    ...mapGetters(["job"]),
    graphStyle() {
      return {
        height: 300,
      };
    },
  },
  data() {
    return {
      dataLoaded: false,
      jobName: "",
      jobFamily: "",
      // 25, 50, 75, 90
      percentiles: [],
      datacollection: [],
      chartLabels: [],
      perPage: 5,
      relatedJobs: [],
      salariesApiEndpoint: `api/user/salaries?searchColumn=jobFamily&searchKeyword=${this.$route.params.id}&searchType=eq&currency=ngn`,
      salaryColumns: [
        {
          name: "company-slot",
          title:
            "<span class='text-sm md:text-base'>Company</span> <br> <small class='text-black-light font-light'>Location | Date</small>",
          sortField: "companyName",
        },
        {
          name: "level-slot",
          title:
            "<span class='text-sm md:text-base'>Job</small> <br> <small class='text-black-light font-light'>Job Family</small>",
          dataClass: "v-align-middle",
        },

        {
          name: "experience-slot",
          title:
            "<span class='text-sm md:text-base'>Years of Experience</span> <br> <small class='text-black-light font-light'>At Company / All Time</small>",
          sortField: "totalExp",
          dataClass: "experience-data hidden lg:block",
        },
        {
          name: "totalsalary-slot",
          title:
            "<span class='text-sm md:text-base'>Total Salary</span> <br> <small class='text-black-light font-light'>Base | Stock | Bonus</small>",
          sortField: "totalYearlyComp",
        },
      ],
    };
  },
  async created() {
    await this.setUpPage(this.$route.params.id);
  },
  methods: {
    ...mapActions(["fetchJob"]),
    setJobAndJobName(jobChildId) {
      this.jobName = jobFamilies(jobChildId);
      this.jobFamily = getJobFamilyNameFromChildId(jobChildId);
    },
    setUpRelatedJobs(jobId) {
      this.relatedJobs = getAllRelatedJobs(jobId);
    },
    fillGraphData(graphData) {
      let labels = [];
      let graphDataPoints = [];

      // For label
      graphData.map((data) => {
        let tempLabel;
        tempLabel = `₦
${FILTERS.formatTotalSalary(data.s)}-${FILTERS.formatTotalSalary(data.e)}`;
        labels.push(tempLabel);
      });

      // For data
      graphData.map((data) => {
        graphDataPoints.push(data.c);
      });

      this.chartLabels = labels;
      this.datacollection = graphDataPoints;
    },
    perPageChange(perPageValue) {
      this.perPage = parseInt(perPageValue);
    },
    async setUpPage(jobChildId) {
      this.dataLoaded = false;

      // Set Job and Job Name
      this.setJobAndJobName(jobChildId);

      await this.fetchJob(jobChildId);

      // Format salaries
      this.percentiles = this.job.percentiles.map((precentile) =>
        FILTERS.formatTotalSalary(precentile)
      );

      // Fill graph
      this.fillGraphData(this.job.graphData);

      // Get related jobs
      this.setUpRelatedJobs(jobChildId);

      this.dataLoaded = true;
    },
  },
  watch: {
    "$route.params.id": {
      handler: function (id) {
        console.log(id);
        this.setUpPage(id);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
</style>