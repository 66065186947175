
<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["chartData", "chartLabels"],
  data() {
    return {
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                display: false
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
          tooltips: {enabled: false},
    hover: {mode: null},
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(
      {
        labels: this.chartLabels,
        datasets: [
          {
            label: "downloads",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            backgroundColor: "#5F4CA5",
            data: this.chartData,
          },
        ],
      },
      this.options
    );
  },
};
</script>

<style>
</style>